

.blackjack {
    color: black;
}


.blackjack__accordian-button {
    background: white;
    border: 1px solid black;
    border-radius: 3px;
    padding: 3px;
    text-align: center;
}

.blackjack__runstrategy-params {
    display: flex;
    flex-direction: row;
}

.blackjack__runstrategy-params__description {
    flex: none;
    display: block;
    width: max(40vw, 150px);
    text-align: end;
    font-weight: 600;
}

.blackjack__runstrategy-params__input-control {
    flex: 1 1 auto;
    display: block;
    margin-bottom: 4px;
    margin-right: 8px;
    padding: 4px;
    margin-top: -4px;
  }

.blackjack__runstrategy-params__input-number[type='number'] {
    max-width: 100px;
}