

/**
* common grid functionality 
*/

.strategy-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
}

.strategy-grid__col {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    flex: 1 1 0;
    border-right: 1px solid black;
}

.strategy-grid__cell {
    display:inline-flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-self: center;
    align-items: center;
    border-bottom: 1px solid black;
    text-align: center;
}


.strategy-grid__cell--header, .strategy-grid__cell--left-col {
    background: #eee;
}