.app {
  padding: 0 15px;
}

.app__popup-header,  .app__popup-body{
  margin: auto;
  text-align: center;
}








