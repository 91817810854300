

.nbs-container {
    max-width: 1100px;
    margin: 0 auto;
    /* padding: 0 15px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .nbs-navbar {
    height: 60px;
    /* background-color: #fef7e5; */
    position: relative;
  }
  
  .nbs-logo {
    align-self: flex-start;
  }
  
  .nbs-menu-icon {
    display: none;
    /* width: 100px;
    height: 100px; */
  }
  
  .nbs-nav-elements {
    z-index: 4;
  }


  
  .nbs-nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
  }
  
  .nbs-nav-elements ul li:not(:last-child) {
    margin-right: 60px;
  }
  
  .nbs-nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #2f234f;
    text-decoration: none;
  }
  
  .nbs-nav-elements ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
  }
  
  .nbs-nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
  }
  
  @media (max-width: 768px) {
    .nbs-nav-elements ul li:not(:last-child) {
      margin-right: 30px;
    }
  }
  
  @media (max-width: 600px) {
    .nbs-menu-icon {
      display: block;
      cursor: pointer;
    }
  
    .nbs-nav-elements {
      position: absolute;
      right: 0;
      top: 60px;
      background-color: #eee;
      /* border: 1px solid black; */
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      width: 0px;
      height: calc(100vh - 65px);
      transition: all 0.3s ease-in;
      overflow: hidden;
    }
  
    .nbs-nav-elements.active {
      width: 270px;
    }
  
    .nbs-nav-elements ul {
      display: flex;
      flex-direction: column;
    }
  
    .nbs-nav-elements ul li {
      margin-right: unset;
      margin-top: 22px;
    }
  }