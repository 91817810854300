.strategy-surrender__col {
    max-width: 60px;
}

.strategy-surrender__cell {
    height: 30px;
    width: 50px;
}

.strategy-surrender__cell--header {
    height: 25px;
}
