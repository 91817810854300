.square {
  background-color: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
  color: black;
}

.square.square--complete-transform {
  transition-property: background-color, color;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  background-color: blue;
  color: white;
  border-color: black;
}

.square--modified {
  background-color: goldenrod;
}

.square--inferred {
  background-color: beige;
}

.square--column-complete, .square--row-complete, .square--section-complete, .square--value-complete {
  background-color: lightgreen;
}

.square--guess {
  background-color: darksalmon;
}

.square--last {
  color: red;
  font-size: 30px;
  border-color: black;
}


.section {
  border: 3px solid #999;
  float: left;
}


.section__square-row:after {
  clear: both;
  content: '';
  display: table;
}

.game_info__status {
  margin-top: 10px;

  text-align: center;
  width: 220px;

  border: 3px solid black;
  border-radius: 3px;
}

.game_info__status.status--modified {
  background-color: goldenrod;
}

.game_info__status.status--inferred {
  background-color: beige;
}

.game_info__status.status--guess {
  background-color: darksalmon;
}

.game_info__status.status--column-complete, .game_info__status.status--row-complete, .game_info__status.status--section-complete, .game_info__status.status--value-complete {
  background-color: lightgreen;
}

.board {
  border: 2px solid #999;
}


.board__section-row:after {
  clear: both;
  content: '';
  display: table;
}

.game {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: flex-start; */

  /* border: 2px dashed blueviolet; */
}

@media (max-width: 500px) {
  .game {
    flex-direction: column;
  }  
}

.game-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin: 10px;
  min-width: 200px;
  /* border: 2px dashed red; */
}

.game-info__column {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  max-width:300px;

  /* border: 2px dashed darkolivegreen; */
}

.game-info__select {
  width: 220px;
  text-align: center;
}

.game-info__navigation {
  max-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;


  /* border: 2px dashed green; */
}

.game-board {
  display: flex;
  flex-direction: row;
  justify-content: center;

  min-width: 350px;


  /* border: 2px dashed pink; */
}

.game__nav-button {
  background-color: #eee;
  margin: 10px;
  min-width: 100px;
}